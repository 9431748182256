import VideoHero from '@/components/cms/VideoHero';

import DynamicComponent from './DynamicComponent';

const VideoWrapper = ({ blok }) => {
  const { video, posterImage, autoPlay, footer, loop, videoUrl } = blok;
  const footerChildren = footer.map((blok) => <DynamicComponent blok={blok} key={blok._uid} />);

  return (
    <VideoHero
      video={video}
      poster={posterImage}
      autoPlay={autoPlay}
      footerChildren={footerChildren}
      loop={loop}
      videoUrl={videoUrl}
    />
  );
};

export default VideoWrapper;
